// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
// Added for SchoolBranch Color Scheme - GG 4/25/21
// Based on ./theme/_variables


// $white: #FFFFFF !default;
// $gray-100: #F9FBFD !default;
// $gray-200: #F1F4F8 !default;
// $gray-300: #D9E2EF !default;
// $gray-400: #C6D3E6 !default;
// $gray-500: #ABBCD5 !default;
// $gray-600: #869AB8 !default;
// $gray-700: #506690 !default;
// $gray-800: #384C74 !default;
// $gray-900: #1B2A4E !default;
// $black: #161C2D !default;

$primary: #33691E !default;
$secondary: #629749 !default;
$primary-desat: #7ec76b !default; // NEW
$success: #33691E !default;
// $info: #7C69EF !default;
// $warning: #FAD776 !default;
// $danger: #DF4759 !default;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;
